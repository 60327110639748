import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LambdaDetail.css'; // Import CSS for styling

const environments = {
    '366900496230': {
        name: 'Development',
        background: 'development'
    },
    '832492651218': {
        name: 'Staging',
        background: 'staging'
    },
    '654654261442': {
        name: 'Prod-Cell-One',
        background: 'prod-cell-one'
    }
};

function LambdaDetail() {
    const { functionName } = useParams(); // Get functionName from params
    const navigate = useNavigate(); // Initialize useNavigate
    const [lambdaDetails, setLambdaDetails] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentRedeployInfo, setCurrentRedeployInfo] = useState({});
    const [selectedVersion, setSelectedVersion] = useState('');

    useEffect(() => {
        fetchLambdaDetails();
    }, [functionName]);

    const fetchLambdaDetails = async () => {
        try {
            const response = await axios.get(`/api/lambda`, {
                params: { function_name: functionName }
            });
            console.log(response);
            setLambdaDetails(response.data);
            // Set the selected version to the active version initially
            if (response.data && Object.keys(response.data).length > 0) {
                const firstAccountId = Object.keys(response.data)[0];
                setSelectedVersion(response.data[firstAccountId].tags.Tags.version);
            }
        } catch (error) {
            console.error('Error fetching lambda details:', error);
        }
    };

    const deploy = async (accountId, functionName, version) => {
        console.log(`Redeploying ${functionName} for account ${accountId} with version ${version}`);
        console.log(lambdaDetails)
        const team = lambdaDetails["366900496230"].tags.Tags.team

        const body = {
            function_name: functionName,
            bucket_name: `${team}-lambda-versions`,
            object_key: `${functionName}/${version}`,
            account_id: accountId
        }

        console.log(body)
        try {
            const response = await axios.post('/api/deploy-lambda', body);

            console.log(response)
        } catch (err) {
            console.error('Error deploying lambda:', err);
        }
        setShowModal(false);
    };

    const handleRedeployClick = (accountId, functionName, version) => {
        if (environments[accountId].name === 'Prod-Cell-One') {
            setCurrentRedeployInfo({ accountId, functionName, version });
            setShowModal(true);
        } else {
            deploy(accountId, functionName, version);
        }
    };

    const handleConfirmRedeploy = () => {
        const { accountId, functionName, version } = currentRedeployInfo;
        deploy(accountId, functionName, version);
    };

    const handleDeployClick = (accountId, functionName) => {
        if (environments[accountId].name === 'Prod-Cell-One') {
            setCurrentRedeployInfo({ accountId, functionName, version: selectedVersion });
            setShowModal(true);
        } else {
            deploy(accountId, functionName, selectedVersion);
        }
    };

    if (!lambdaDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-3" onClick={() => navigate('/')}>Back to Dashboard</button>
            <h1>{lambdaDetails[Object.keys(lambdaDetails)[0]].function_name}</h1>
            {Object.entries(lambdaDetails).map(([accountId, details]) => {
                if (accountId === "versions") {
                    return
                }
                if (details.error) {
                    return
                }
                return (
                    <div key={accountId} className={`card mb-4 ${environments[accountId].background}`}>
                        <div className="card-body">
                            <h2 className={`d-flex justify-content-between align-items-center`}>
                                {environments[accountId].name}
                                <div>{details.tags.Tags.version}</div>
                                <button
                                    className="btn btn-light"
                                    onClick={() => handleRedeployClick(accountId, details.function_name, details.tags.Tags.version)}
                                >
                                    Redeploy
                                </button>
                            </h2>
                            <div className="mb-3 d-flex justify-content-between">
                                <label htmlFor={`versionSelect-${accountId}`} className="form-label">Select Version to Deploy</label>
                                <select
                                    id={`versionSelect-${accountId}`}
                                    value={selectedVersion}
                                    className="form-select form-select-sm"
                                    onChange={(e) => setSelectedVersion(e.target.value)}
                                >
                                    {lambdaDetails.versions.map((version) => (
                                        <option key={version} value={version}>{version}</option>
                                    ))}
                                </select>
                                <button
                                    className="btn btn-light"
                                    onClick={() => handleDeployClick(accountId, details.function_name)}
                                >
                                    Deploy
                                </button>
                            </div>
                            <p><strong>Project Tag:</strong> {details.tags.Tags.project}</p>
                            <p><strong>Service Tag:</strong> {details.tags.Tags.service}</p>
                            <p><strong>Team Tag:</strong> {details.tags.Tags.team}</p>
                            <p>
                                <strong>Workspace Tag:</strong>
                                <a href={`https://app.terraform.io/app/Jump_Engineering/workspaces/${details.tags.Tags.workspace}`} target="_blank" rel="noopener noreferrer">
                                    {details.tags.Tags.workspace}
                                </a>
                            </p>
                        </div>
                    </div>
                )
            })}

            {/* Modal for confirmation */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h4>Are you sure you want to deploy production?</h4>
                        <button className="btn btn-danger" onClick={handleConfirmRedeploy}>Yes</button>
                        <button className="btn btn-secondary" onClick={() => setShowModal(false)}>No</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LambdaDetail;

